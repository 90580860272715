//custom-selection color
::selection {
	color: #fff;
	background: #000;
}

::-moz-selection {
    color: #fff;
    background: #000;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}

/* make sure to set some focus styles for accessibility */
:focus {
    outline: 0;
}

ol, ul {
	list-style: none;
}
body {
    font-family: $font-primary !important;
    font-size: $text-md; // 14px by default
    margin: 0;
    color: $text-color;
    background: $app-body-bg;
}
a {
    color: $light-blue;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}
button {
    cursor: pointer;
}

b,strong {
    font-weight: $font-weight-bold;
}
img {
    border: 0; /* 1 */
    -ms-interpolation-mode: bicubic; /* 2 */
}

// Custom Scrollbar
//@include scrollbars(7px, $gray-500);

.rtl {
  direction: rtl;
}

h1{
    font-weight: 500;
    font-size: 20px;
    color: $text-color;
    margin-bottom: 5px;
}
h2{
    font-weight: 500;
    font-size: 18px;
    color: $text-color;
    margin-bottom: 5px;
}
h3{
    font-weight: 500;
    font-size: 15px;
    color: $text-color;
    margin-bottom: 5px;
}
h4{
    font-weight: 500;
    font-size: $text-md;
    color: $text-color;
    margin-bottom: 5px;
}
h5{
    font-weight: 500;
    font-size: 11px;
    color: $text-color;
    margin-bottom: 5px;
}

