// Source Sans Pro

$fontpath: "/assets/fonts/custom-fonts";

@font-face {
  font-family: "Source Sans Pro";
  src: url("#{$fontpath}/source-sans-pro/SourceSansPro-Light.woff2") format("woff2"),
    url("#{$fontpath}/source-sans-pro/SourceSansPro-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("#{$fontpath}/source-sans-pro/SourceSansPro-Regular.woff2") format("woff2"),
    url("#{$fontpath}/source-sans-pro/SourceSansPro-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("#{$fontpath}/source-sans-pro/SourceSansPro-ExtraLight.woff2") format("woff2"),
    url("#{$fontpath}/source-sans-pro/SourceSansPro-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("#{$fontpath}/source-sans-pro/SourceSansPro-BlackItalic.woff2") format("woff2"),
    url("#{$fontpath}/source-sans-pro/SourceSansPro-BlackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("#{$fontpath}/source-sans-pro/SourceSansPro-Italic.woff2") format("woff2"),
    url("#{$fontpath}/source-sans-pro/SourceSansPro-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("#{$fontpath}/source-sans-pro/SourceSansPro-BoldItalic.woff2") format("woff2"),
    url("#{$fontpath}/source-sans-pro/SourceSansPro-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("#{$fontpath}/source-sans-pro/SourceSansPro-Black.woff2") format("woff2"),
    url("#{$fontpath}/source-sans-pro/SourceSansPro-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("#{$fontpath}/source-sans-pro/SourceSansPro-SemiBold.woff2") format("woff2"),
    url("#{$fontpath}/source-sans-pro/SourceSansPro-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("#{$fontpath}/source-sans-pro/SourceSansPro-ExtraLightItalic.woff2") format("woff2"),
    url("#{$fontpath}/source-sans-pro/SourceSansPro-ExtraLightItalic.woff") format("woff");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("#{$fontpath}/source-sans-pro/SourceSansPro-LightItalic.woff2") format("woff2"),
    url("#{$fontpath}/source-sans-pro/SourceSansPro-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("#{$fontpath}/source-sans-pro/SourceSansPro-Bold.woff2") format("woff2"),
    url("#{$fontpath}/source-sans-pro/SourceSansPro-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("#{$fontpath}/source-sans-pro/SourceSansPro-SemiBoldItalic.woff2") format("woff2"),
    url("#{$fontpath}/source-sans-pro/SourceSansPro-SemiBoldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
}

// Poppins font

@font-face {
  font-family: "Poppins";
  src: url("#{$fontpath}/poppins/Poppins-Black.woff2") format("woff2"), url("#{$fontpath}/poppins/Poppins-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url("#{$fontpath}/poppins/Poppins-BlackItalic.woff2") format("woff2"), url("#{$fontpath}/poppins/Poppins-BlackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Poppins";
  src: url("#{$fontpath}/poppins/Poppins-ExtraBold.woff2") format("woff2"), url("#{$fontpath}/poppins/Poppins-ExtraBold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url("#{$fontpath}/poppins/Poppins-ExtraBoldItalic.woff2") format("woff2"), url("#{$fontpath}/poppins/Poppins-ExtraBoldItalic.woff") format("woff");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Poppins";
  src: url("#{$fontpath}/poppins/Poppins-Bold.woff2") format("woff2"), url("#{$fontpath}/poppins/Poppins-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url("#{$fontpath}/poppins/Poppins-BoldItalic.woff2") format("woff2"), url("#{$fontpath}/poppins/Poppins-BoldItalic.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Poppins";
  src: url("#{$fontpath}/poppins/Poppins-SemiBold.woff2") format("woff2"), url("#{$fontpath}/poppins/Poppins-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url("#{$fontpath}/poppins/Poppins-SemiBoldItalic.woff2") format("woff2"), url("#{$fontpath}/poppins/Poppins-SemiBoldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Poppins";
  src: url("#{$fontpath}/poppins/Poppins-Medium.woff2") format("woff2"), url("#{$fontpath}/poppins/Poppins-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url("#{$fontpath}/poppins/Poppins-MediumItalic.woff2") format("woff2"), url("#{$fontpath}/poppins/Poppins-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Poppins";
  src: url("#{$fontpath}/poppins/Poppins-Regular.woff2") format("woff2"), url("#{$fontpath}/poppins/Poppins-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("#{$fontpath}/poppins/Poppins-Light.woff2") format("woff2"), url("#{$fontpath}/poppins/Poppins-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url("#{$fontpath}/poppins/Poppins-LightItalic.woff2") format("woff2"), url("#{$fontpath}/poppins/Poppins-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Poppins";
  src: url("#{$fontpath}/poppins/Poppins-ExtraLight.woff2") format("woff2"), url("#{$fontpath}/poppins/Poppins-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url("#{$fontpath}/poppins/Poppins-ExtraLightItalic.woff2") format("woff2"), url("#{$fontpath}/poppins/Poppins-ExtraLightItalic.woff") format("woff");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Poppins";
  src: url("#{$fontpath}/poppins/Poppins-Thin.woff2") format("woff2"), url("#{$fontpath}/poppins/Poppins-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url("#{$fontpath}/poppins/Poppins-ThinItalic.woff2") format("woff2"), url("#{$fontpath}/poppins/Poppins-ThinItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic;
}
