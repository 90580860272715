@import '../core/mixins/custom-scrollbar';

// Custom Scrollbar
@include scrollbars(7px, $dark-grey);

.app-l-heading--h3 {
  color: $text-color;
  font-weight: 500;
  font-size: 20px;
  margin: 0 0 15px;
  padding: 0;

  @media screen and (max-width: 767px) {
    font-size: 16px;
  }
}

.app-l-heading--h4 {
  color: $text-color;
  font-weight: 500;
  font-size: 15px;
  margin: 0 0 15px;
  padding: 0;

  @media screen and (max-width: 767px) {
    font-size: 13px;
  }
}
.app-l-layout-wrapper {
  height: 100vh;
}
.h-90{
height: 90% !important;
}
.app-l-page-content {
  height: calc(100% - 102px);
  min-height: 0;
  padding: 24px 0px;
  overflow: auto;
  position: relative;

  @include flexbox();
  @include flex-direction(column);

  &.app-l-full-screen {
    height: calc(100% - 51px);
  }

  &.scrollable {
    overflow: auto;
    overflow-x: hidden;
  }
}

.app-l-container {
  max-width: 100%;
  margin: 0 100px;
  padding: 0 16px;

  @media screen and (max-width: 767px) {
    margin: 0 16px;
  }
  @media screen and (max-width: 480px) {
    padding: 0 12px;
  }

  &.vertical-stack {
    height: 100%;
    min-height: 0;

    @include flexbox();
    @include flex-direction(column);
  }
}

.app-l-tab-nav {
  @include flexbox();
  @include align-items(center);

  ul {
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);
    li {
      @include inline-flex();
      a {
        padding: 12px 10px;
        position: relative;
        color: $text-color;
        font-size: 14px;
        font-weight: bold;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 1.25px;
        line-height: normal;
        @include transition(all 0.3s ease);
        &:before {
          content: '';
          position: absolute;
          bottom: 0px;
          top: auto;
          left: 0px;
          right: 0px;
          height: 3px;
          background: rgb(15, 121, 208);
          width: 0px;
          margin: auto;
          @include transition(all 0.3s ease);
        }
        &:hover {
          text-decoration: none;
          color: rgb(15, 121, 208);
          &:before {
            width: 100%;
          }
        }
        &.active {
          color: rgb(15, 121, 208);
          &:before {
            width: 100%;
            @include transition(all 0.3s ease);
          }
        }
      }
    }
  }
}

.app-l-grid-action {
  height: 100%;
  min-height: 0px;
  padding: 0px;

  @include flexbox();
  @include flex-direction(column);

  .app-l-grid-action__top {
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);

    position: relative;
    .app-l-grid-action__top__left {
      margin: 0 auto 0 0;
      width: calc(50% + (405px / 2));
      @include flexbox();
      @include align-items(center);
    }
  }
  .grid-action__view-selector {
    max-width: 205px;
  }
  .grid-action__search {
    padding-left: 24px;
    margin-left: auto;
    margin-bottom: 16px;
    width: 410px;
    position: relative;

    .mat-form-field {
      width: 100%;

      &.mat-focused {
        .mat-form-field-ripple {
          background-color: $app-brand-primary;
        }
        .mat-form-field-label {
          color: $app-brand-primary;
        }
      }

      .mat-form-field-suffix {
        top: -3px;
        font-size: 14px;
        color: #1c2834;
        right: 10px;
      }

      .mat-form-field-wrapper {
        padding-bottom: 0px;

        .mat-form-field-flex {
          padding-right: 32px;

          .mat-form-field-infix {
            .mat-form-field-label-wrapper {
              .mat-form-field-label {
                text-align: center;
              }
            }
          }
        }

        .grid-search-icon-holder {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: auto;
          width: 40px;
          height: 100%;
          background-color: transparent;
          border: solid 1px transparent;

          @include flexbox();
          @include align-items(center);
          @include justify-content(center);

          i {
            font-size: 15px;
            line-height: normal;
            color: $text-color;
            cursor: pointer;
          }
        }

        .mat-form-field-underline {
          bottom: 0;
        }
      }
    }

    .grid-search-icon-holder {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: auto;
      width: 40px;
      height: 53px;
      background-color: transparent;
      border: solid 1px transparent;

      @include flexbox();
      @include align-items(center);
      @include justify-content(center);

      i {
        font-size: 15px;
        line-height: normal;
        color: rgb(28, 40, 52);
        cursor: pointer;
      }
    }
  }
  .app-l-grid-action__bottom {
    min-width: 0;
    min-height: 0;

    @include flexbox();
    @include align-items(center);
    @include justify-content(center);

    span {
      color: rgba(28, 40, 52, 0.8);
      font-size: 12px;
      font-weight: normal;
      letter-spacing: 0.15px;
      line-height: normal;
    }
    i {
      margin-left: 8px;
      color: #1c2834;
      font-size: 16px;
    }
  }
}
.app-l-not-found {
  @include flexbox();
  @include flex-direction(column);
  @include align-items(center);
  @include justify-content(center);
  min-height: 400px;
  background: $white;
  img {
    max-width: 100px;
  }
  .app-l-not-found__text {
    margin-top: 18px;
    p {
      margin-bottom: 0px;
      color: rgb(28, 40, 52);
      font-size: 20px;
      font-weight: 400;
      text-align: center;
      line-height: 24px;
      padding-bottom: 0px;
    }
  }
}

.app-l-page-banner {
  width: 100%;
  min-width: 0;
  min-height: 0;

  @include flexbox();
  @include flex-shrink(0);
  @include flex-direction(column);

  &.app-l-page-banner--bg {
    position: relative;
    // background: rgb(5, 42, 73);
    // background: linear-gradient(90deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%)
    // rgb(13, 107, 184);
    background-image: url('/assets/images/hero_background_blue.png');
    // background: linear-gradient(90deg, rgba(5, 42, 73, 1) 0%, rgba(0, 198, 251, 1) 100%);

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: auto;
      bottom: auto;
      z-index: 1;
      width: 100%;
      height: 100%;
      background: url('/assets/images/wave.svg') no-repeat;
      background-size: cover;
      background-position-x: center;
      background-position-y: center;
    }
  }

  .app-l-pg-banner-head {
    max-width: 100%;
    margin: 0 100px;
    padding: 8px 0px;
    position: relative;
    z-index: 2;

    @include flexbox();
    @include align-items(center);

    @media screen and (max-width: 767px) {
      margin: 0 16px;
    }

    .app-l-pg-banner-left {
      padding: 4px 0px;

      @include flexbox();
      @include align-items(center);

      .app-l-page-action {
        padding: 4px 8px;
        background: none;
        border: none;
        outline: none;

        @include flexbox();
        @include align-items(center);
        @include justify-content(center);
        @include flex-shrink(0);

        > i {
          margin: auto;
          font-size: 12px;
          color: $white;
          opacity: 0.8;
          cursor: pointer;

          @include transition(opacity 0.3s ease);

          + .app-l-page-action-text {
            margin-left: 8px;
          }
        }

        &:hover {
          i {
            opacity: 1;
          }
          .app-l-page-action-text {
            color: $white;
          }
        }
        &:focus {
          outline: none;

          i {
            opacity: 1;
          }
          .app-l-page-action-text {
            color: $white;
          }
        }

        > .app-l-page-action-text {
          margin: 0;
          font-size: 14px;
          font-weight: 600;
          letter-spacing: 1.25px;
          line-height: 16px;
          text-transform: uppercase;
          color: rgba(255, 255, 255, 0.8);
          cursor: pointer;

          @include transition(color 0.3s ease);
        }
      }
    }

    .app-l-pg-banner-right {
      margin: 0 0 0 auto;
      max-width: 100%;

      @include flexbox();
      @include align-items(center);

      .app-l-banner-controls-wrap {
        margin: -8px;
        padding: 0;
        min-width: 0;

        @include flexbox();
        @include align-items(center);

        .app-l-banner-control-btn {
          padding: 8px;
          min-width: 18px;
          min-height: 18px;
          outline: none;
          position: relative;
          line-height: 0;
          font-size: 0;
          cursor: pointer;

          @include flexbox();
          @include align-items(center);
          @include justify-content(center);
          @include transition(background 0.2s ease-in-out);

          &:hover,
          &:focus {
            > i {
              opacity: 1;
            }
          }

          > i {
            font-size: 17px;
            line-height: normal;
            cursor: pointer;
            color: $white;
            opacity: 0.7;

            @include transition(
              color 0.2s ease-in-out,
              opacity 0.2s ease-in-out
            );
          }
        }
        .app-c-label {
          min-width: 120px;
          max-width: 120px;
          overflow: hidden;
        }
      }
    }

    + .app-l-pg-banner-body {
      padding-top: 0px;
    }
  }
  .app-l-pg-banner-body {
    padding: 8px;
    margin: 0 100px;
    min-width: 0;
    min-height: 0;
    position: relative;
    z-index: 2;

    @include flexbox();
    @include flex-direction(column);

    @media screen and (max-width: 767px) {
      margin: 0 16px;
    }

    .app-l-pg-banner-body-head {
      width: 100%;

      @include flexbox();
      @include align-items(center);

      .app-l-chain {
        .app-l-chain-link {
          padding: 4px 16px 4px 0px;
          margin: 0 14px 0 0;

          &:before {
            content: '';
            width: 2px;
            height: 20px;
            background: $white;
          }
        }
      }

      + .app-l-pg-banner-body-content {
        margin-top: 8px;
      }
    }

    .app-l-pg-banner-body-content {
      width: 100%;
      min-width: 0;
      padding: 0 0 4px 0;

      @include flexbox();

      .app-l-pg-banner-content {
        margin: 0px;
        max-width: 100%;

        @include flexbox();
        @include flex-grow(1);
        @include flex-direction(column);

        .app-l-pg-content-group {
          min-width: 0;
          min-height: 0;
          margin: 0px -8px;

          @include flexbox();
          @include flex-grow(1);
          @include flex-wrap(wrap);

          + .app-l-pg-content-group {
            margin-top: 4px;
          }

          .app-l-label-value-group {
            margin: 0px 8px;
            min-height: 0;

            width: calc(25% - 16px);

            @include flex-wrap(nowrap);
            @include align-items(flex-start);

            @media screen and (max-width: 991px) {
              width: calc(50% - 16px);
            }
            @media screen and (max-width: 767px) {
              width: calc(100% - 16px);

              @include flex-wrap(wrap);
            }

            .app-l-label-holder {
              max-width: 50%;
              width: 50%;

              @media screen and (max-width: 767px) {
                max-width: 100%;
                width: 100%;
              }
            }
            .app-l-value-holder {
              max-width: 50%;
              width: 50%;

              @media screen and (max-width: 767px) {
                max-width: 100%;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

.app-l-text-icon-group {
  min-width: 0;

  @include inline-flex();
  @include align-items(center);
  @include flex-wrap(wrap);

  > h4 {
    margin: 0 4px 0 0;
    font-size: 24px;
    line-height: 26px;
    font-weight: 400;
    color: $white;
    padding: 4px 0px;
    font-family: $font-secondary;
  }

  .app-l-icons-holder {
    min-width: 0;
    min-height: 0;

    @include inline-flex();
    @include align-items(center);
    @include flex-wrap(wrap);

    .app-l-banner-icon {
      height: 26px;
      width: 26px;
      padding: 4px;
      cursor: pointer;
      outline: none;
      background: none;
      border: none;

      @include inline-flex();
      @include align-items(center);
      @include justify-content(center);
      @include flex-shrink(0);

      &:hover {
        i {
          color: rgba(255, 255, 255, 1);
        }
      }

      i {
        font-size: 15px;
        line-height: normal;
        color: rgba(255, 255, 255, 0.8);

        @include transition(color 0.3s ease);
      }
    }
  }
}

.app-l-chain {
  min-width: 0;

  @include inline-flex();
  @include align-items(center);
  @include flex-wrap(wrap);

  .app-l-chain-link {
    min-width: 0;
    padding: 4px 8px 4px 0px;
    margin: 0 16px 0 0;
    position: relative;

    @include inline-flex();
    @include align-items(center);

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: auto;

      width: 2px;
      height: 20px;
      margin: auto;
      background: $white;
    }

    &:last-child {
      padding: 4px 4px 4px 0px;
      margin: 0;

      &:before {
        display: none;
      }
    }
  }
}

.app-l-label-value-group {
  min-width: 0;

  @include flexbox();
  @include align-items(center);

  @media screen and (max-width: 767px) {
    @include flex-wrap(wrap);
  }

  .app-l-label-holder {
    min-width: 0;
    margin: 0 0 0px 0;
    min-width: 100px;

    @include flexbox();

    .app-l-label-text-holder {
      min-width: 0;
      position: relative;
      padding-right: 8px;

      @include inline-flex();
      @include flex-direction(column);
    }

    .app-l-label-text {
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0.15px;
      line-height: 16px;
      color: rgba(255, 255, 255, 0.8);
      margin: 4px 0px 1px 0px;

      + .app-l-label-progress {
        margin-top: 2px;
      }
    }

    .app-l-label-progress {
      max-width: 100%;
      min-width: 0;
      margin: 0 -1px;

      @include flexbox();
      @include align-items(center);

      .app-l-label-bar {
        max-width: 100%;
        height: 2px;
        min-width: 5px;
        margin: 0 1px;
        background-color: rgba(255, 255, 255, 0.2);

        @include flex-grow(1);

        &.active {
          background-color: rgba(255, 255, 255, 1);
        }
      }
    }
  }

  .app-l-value-holder {
    min-width: 0;
    position: relative;
    margin: 0 0 0px 0;

    @include flexbox();
    @include align-items(center);

    .app-l-value-text {
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0.25px;
      line-height: 24px;
      color: $white;
      margin: 0;

      + .app-l-value-icon {
        margin-left: 8px;
      }
    }

    .app-l-value-icon {
      @include flex-shrink(0);

      > i {
        font-size: 11px;
        line-height: normal;
        color: $white;
      }
    }
  }
}

.app-l-content-heading {
  font-size: 37px !important;
  font-weight: 300 !important;
  color: rgb(44, 62, 80);
  letter-spacing: 0.27px;
  line-height: 46px;
}

.app-l-content-head {
  width: 100%;
  min-width: 0;

  @include flexbox();
  @include align-items(center);

  .app-l-content-head-left {
    min-width: 0;

    @include flexbox();
    @include align-items(center);

    .app-l-content-heading {
      margin: 0;
    }
  }

  .app-l-content-head-right {
    min-width: 0;
    margin: 0 0 0 auto;

    @include flexbox();
    @include align-items(center);

    .app-l-head-btn-group {
      max-width: 100%;
      margin: -4px;

      @include flexbox();
      @include align-items(center);

      @media screen and (max-width: 576px) {
        @include flex-wrap(wrap);
      }

      .app-c-btn {
        margin: 4px;

        @include flex-shrink(0);
      }
    }
  }
}

.app-l-link-group {
  min-width: 0;

  @include flexbox();
  @include align-items(flex-start);
  @include border-radius(4px);

  .app-l-link-btn {
    width: 100%;
    padding: 8px;
    border: none;
    background-color: $white;
    outline: none;

    @include flexbox();
    @include align-items(center);
    @include transition(color 0.3s ease);

    &:first-child {
      @include border-radius(4px 4px 0px 0px);
    }
    &:last-child {
      @include border-radius(0px 0px 4px 4px);
    }

    &:focus,
    &.selected {
      background-color: $app-brand-primary;
    }
    &:hover {
      background-color: $app-brand-primary;
    }
  }
}

.mat-form-field-appearance-legacy {
  .mat-form-field-underline {
    background-color: rgba(0, 0, 0, 0.7);
  }
}

.wh-tile {
  border: 1px solid #2a9fd8;
  background: rgba(0, 173, 255, 0.54);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-indent: -9999px;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -webkit-user-drag: none;
  opacity: 0;
  z-index: 2;
  cursor: pointer;
}
