.app-l-header {
  @include flexbox();
  @include flex-direction(column);
}
.app-l-search-wrapper {
  width: 400px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99999;
  background: white;
  .app-c-side-menu-search-wrapper {
    margin-top: 24px;
    margin-left: 16px;
    .app-l-search-left-wrapper {
      margin-top: 14px;
      @include flexbox();

      @include justify-content(space-between);
      .app-l-arrow {
        @include flexbox();
        @include align-items(flex-start);
        margin-top: 20px;
        margin-left: 10px;
        // @include justify-content(center);
      }
      .grid-action__search-wrapper {
        @include flexbox();
        @include flex-direction(column);

        .grid-action__search {
          background: rgb(242, 244, 247);

          width: 317px;
          margin-right: 25px;
          position: relative;
          font-size: 12px;
          .mat-form-field {
            width: 100%;

            &.mat-focused {
              .mat-form-field-ripple {
                background-color: $app-brand-primary;
              }
              .mat-form-field-label {
                color: $app-brand-primary;
              }
            }

            .mat-form-field-suffix {
              top: -3px;
              font-size: 14px;
              color: #1c2834;
              right: 10px;
            }

            .mat-form-field-wrapper {
              padding-bottom: 0px;

              .mat-form-field-flex {
                padding-right: 32px;
                background: rgb(242, 244, 247);

                .mat-form-field-infix {
                  .mat-form-field-label-wrapper {
                    .mat-form-field-label {
                      text-align: center;
                    }
                  }
                }
              }

              .grid-search-icon-holder {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: auto;
                width: 40px;
                height: 100%;
                background-color: transparent;
                border: solid 1px transparent;

                @include flexbox();
                @include align-items(center);
                @include justify-content(center);

                i {
                  font-size: 15px;
                  line-height: normal;
                  color: $text-color;
                  cursor: pointer;
                }
              }

              .mat-form-field-underline {
                bottom: 0;
              }
            }
          }

          .grid-search-icon-holder {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: auto;
            width: 40px;
            height: 53px;
            background-color: transparent;
            border: solid 1px transparent;

            @include flexbox();
            @include align-items(center);
            @include justify-content(center);

            i {
              font-size: 15px;
              line-height: normal;
              color: rgb(28, 40, 52);
              cursor: pointer;
            }
          }
        }
      }
    }
    .app-l-no-data {
      margin-top: 100px;
      @include flexbox();
      @include flex-direction(column);
      @include align-items(center);
      @include justify-content(center);
      .app-c-no-data {
        color: rgb(44, 62, 80);
        font-weight: 800;
        height: 16px;
        font-style: bold;
        letter-spacing: 1.5px;
        @include flexbox();
        @include justify-content(center);
      }
      .app-c-details {
        color: rgb(44, 62, 80);
        font-weight: normal;
        height: 14px;
        letter-spacing: 1.3px;
        width: 238px;
        @include flexbox();
        @include justify-content(center);
        text-align: center;
        margin: auto;
      }
    }
    .app-l-search-details {
      .app-c-search-heading {
        font-size: 12px;
        color: rgba(28, 40, 52, 0.6);
        font-weight: bold;
        height: 16px;
        letter-spacing: 1.5px;
        line-height: 16px;
        text-transform: uppercase;
        white-space: nowrap;
        margin-top: 24px;
      }
      .app-c-search-results {
        color: rgba(28, 40, 52, 0.6);
        font-size: 14px;
        font-style: italic;
        font-weight: normal;
        height: 18px;
        letter-spacing: 0.13px;
        margin-top: 10px;
      }
    }
    .app-l-search-main-body {
      height: 700px;
      overflow: auto;
      .app-l-search-content-wrapper {
        @include flexbox();
        @include align-items(center);
        @include justify-content(space-between);
        margin-right: 24px;
        margin-top: 16px;
        transition: all 0.2s ease-in-out;
        .app-l-search-content-left {
          @include flexbox();
          @include flex-direction(column);

          .app-c-label-text {
            color: rgb(28, 40, 52);
            font-size: 16px;
            font-weight: normal;
            letter-spacing: 0.15px;
            cursor: pointer;
          }
          .app-c-label-wrapper {
            @include flexbox();
            @include align-items(center);
            .app-c-dot {
              font-size: 20px;
              margin-left: 7px;
              margin-right: 7px;
              color: gray;
              @include flexbox();
              @include align-items(center);
            }
            .app-c-sub-label {
              color: rgba(28, 40, 52, 0.6);
              font-size: 14px;
              font-weight: normal;
              letter-spacing: 0.13px;
              cursor: pointer;
              @include flexbox();
              @include align-items(center);
            }
          }
        }
        .app-l-search-content-right {
          @include flexbox();
          @include flex-direction(column);
          @include align-items(center);
          .icon-arrow-right-single {
            color: #8080804d;
          }
        }
        &:hover {
          cursor: pointer;
          background-color: #80808014;
        }
      }
    }
    .app-c-sidemenu-search-logo {
      color: black;
      font-size: 30px;
      font-weight: 600;
      margin-left: 8px;
    }
  }
}
.app-l-header__inner-wrap {
  @include flexbox();
  @include align-items(center);
  .app-l-header__left {
    margin-right: auto;
    margin-top: 80px;
    @include inline-flex();
    @include flex-direction(column);
    @include justify-content(flex-start);

    a {
      line-height: normal;
      font-size: 0;

      @include inline-flex();
    }
    h1 {
      margin: 0px;
      line-height: normal;
      font-size: 0;

      @include inline-flex();
    }
  }
  .app-l-header__right {
    margin-left: auto;
    @include flexbox();
    @include align-items(center);
    .app-l-header__feedback-link {
      color: $app-brand-primary;
      font-size: 14px;
      font-weight: normal;
      letter-spacing: 0.25px;
      line-height: normal;
      text-decoration: underline;
    }
    .app-l-header__user {
      width: 30px;
      height: 30px;
      margin-left: 24px;
      @include border-radius(50%);
      background: #c7d2e0;
      border: solid 1px #c7d2e0;
      @include flexbox();
      @include align-items(center);
      @include justify-content(center);
      &:active,
      &:focus {
        outline: none;
        border: none;
      }
      .dropdown-toggle,
      span {
        color: rgba(28, 40, 52, 0.8);
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.25px;
        line-height: normal;
        text-align: center;
        &::after {
          display: none;
        }
      }
    }
  }
}

.app-l-header__top {
  background: $primary-color;
  padding: 7px 0px;
  border-bottom: 1px solid #f2f4f7;
  position: fixed;
  top: 0;
  left: 0;
  right: auto;
  bottom: -1px;
  width: 64px;
  z-index: 99999;
  @include flexbox();
  @include justify-content(space-between);
  @include align-items(center);
  @include flex-direction(column);
  @include transition(all 0.6s ease-in-out);

  .app-c-label {
    color: rgb(255, 255, 255);
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 0.25px;
    line-height: 24px;
    display: none;
    min-width: 155px;
    max-width: 155px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .app-l-side-menu-top {
    @include flexbox();
    @include flex-direction(column);
    width: 100%;
  }
  .app-c-sidemenu-logo1 {
    color: white;
    display: none;
  }
  .app-l-side-menu-bottom {
    @include flexbox();
    @include flex-direction(column);
    @include justify-content(flex-end);
    width: 100%;
    position: absolute;
    bottom: 0px;
  }
  .app-c-side-menu-icon-wrapper {
    width: 100%;
    height: 53px;
    cursor: pointer;
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);
    .app-c-sidemenu-logo {
      color: white;
      font-size: 35px;
      cursor: pointer;
    }
    .app-c-logo-text {
      font-size: 25px;
      font-weight: bold;
      cursor: pointer;
    }
    .icon {
      color: white;
      padding: 20px;
    }
    &:hover {
      background-color: black !important;
      .app-l-search__menu {
        display: block;
      }
    }
    .app-l-header__right {
      @include flexbox();
      @include align-items(center);
    }
    .app-c-usericon {
      width: 15px;
      height: auto;
    }
  }
  // Taking out the hover as per WLMS-3633
  // &:hover {
  //   width: 248px;
  //   .app-c-label {
  //     display: block;
  //     cursor: pointer;
  //   }
  //   .app-c-sidemenu-logo1 {
  //     @include flexbox();
  //     @include align-items(center);
  //     @include justify-content(left);
  //     text-align: left;
  //     display: block;
  //     color: white;
  //     font-size: 25px;
  //   }
  //   .app-c-sidemenu-logo {
  //     // display: none;
  //   }
  // }
}

.app-l-header__bottom {
  background: $blue-decor no-repeat;
  background-position: left bottom;
  padding: 10px 0px;
  height: 149px;
  min-height: 143px;
  background-size: cover;
  .app-l-header__left {
    margin-right: auto;
    .app-c-label {
      color: rgba(28, 40, 52, 0.8);
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 1.5px;
      line-height: 16px;
      text-transform: uppercase;
    }
    h3 {
      color: #1c2834;
      font-size: 21px;
      font-weight: normal;
      line-height: normal;
      margin-top: 0px;
      margin-bottom: 0px;
      span {
        color: #1c2834;
        font-weight: 700;
        margin-left: 10px;
        font-size: 21px;
        line-height: normal;
      }
    }
  }
}

.app-c-menu-item-label{
  min-width :169px !important;
  max-width :169px !important;
  padding-left: 25px;
}
