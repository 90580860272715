.app-c-tooltip--primary {
  &.tooltip {
    &.show {
      opacity: 1;
    }
    .tooltip-inner {
      background: $tooltip-background;
      padding: 12px;
      font-size: $text-md;
      color: $white;
      line-height: 18px;
      text-align: left;

      @include border-radius(4px);

      h3 {
        color: $white;
      }
    }
    &.bs-tooltip-top {
      .tooltip-arrow {
        &:before {
          border-width: 7px 8px;
          border-top-color: $tooltip-arrow-background;
        }
      }
    }
    &.bs-tooltip-bottom {
      .tooltip-arrow {
        &:before {
          border-width: 7px 8px;
          border-bottom-color: $tooltip-arrow-background;
        }
      }
    }
    &.bs-tooltip-right {
      .tooltip-arrow {
        &:before {
          border-width: 8px 7px;
          border-right-color: $tooltip-arrow-background;
        }
      }
    }
    &.bs-tooltip-left {
      .tooltip-arrow {
        &:before {
          border-width: 8px 7px;
          border-left-color: $tooltip-arrow-background;
        }
      }
    }
  }
}
.mat-tooltip {
  margin: 5px !important;
  background: #404040;
  padding: 5px 10px !important;
  line-height: normal;
  font-family: $font-secondary;
  font-size: 12px;
}
