.red-snackbar {
    background-color: #ea3d2f;
    color: white !important;
  }
  
  .red-snackbar span {
    color: white !important;
  }
  
  .warning-snackbar {
    background-color: #ff9800;
    color: white !important;
  }
  
  .warning-snackbar span {
    color: white !important;
  }
  
  .info-snackbar {
    background-color: #2196f3;
    color: white !important;
  }
  
  .info-snackbar span {
    color: white !important;
  }
  
  .success-snackbar {
    background-color: #4caf50;
    color: white !important;
  }
  
  .success-snackbar span {
    color: white !important;
  }