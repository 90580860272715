.app-c-file-upload{
    @include flexbox();

    @media screen and (max-width: 480px){
        display: block;
    }

    .app-l-file-upload__btn{
        line-height: normal;

        @include flex-shrink(0);

        label{
            display: block;
            overflow: hidden;
            position: relative;

            input{
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
    .app-l-file__list{
        padding: 0 0 0 8px;
        line-height: normal;
        width: 100%;

        @include flexbox();
        @include flex-wrap(wrap);

        @media screen and (max-width: 480px){
            padding-left: 0;
            padding-top: 8px;
        }
        .app-c-chips{
            margin: 0 5px 5px 0;
        }
    }
}