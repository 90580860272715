.modal-content {
  padding: 32px;
  overflow: auto;
}
.modal-header {
  border-bottom: transparent !important;
}
.modal-footer {
  border-top: transparent !important;
}
.modal-title-h4 {
  color: rgb(43, 42, 53);
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.25px;
}

.modal-body-label {
  color: rgb(43, 42, 53);
  font-size: 14px;
  font-weight: normal;
  height: 18px;
  letter-spacing: 0.25px;
  margin-bottom: 50px;
}
