.min-h-200 {
  min-height: 200px !important;
}

.min-h-100 {
  min-height: 100px !important;
}

.ml-20 {
  margin-left: 20px;
}

.mr-20 {
  margin-right: 20px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.m-20{
  margin: 20px 5px 20px 5px;
}

.mr-0 {
  margin-right: 0px;
}
.mb-0{
  margin-bottom: 0px;
}
