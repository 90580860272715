@import '../core/mixins/box-shadow';
@import '../core/mixins/flexbox';
@import '../core/mixins/transform';
@import '../core/mixins/transitions';
@import '../core/mixins/border-radius';

@import '../variables/variables';
@import '../theme/theme';

.form-control {
  padding: 6px 10px;
  height: 32px;
  min-height: 32px;
  font-size: $text-md;
  font-weight: 400;
  color: $text-dark-extra;
  border: 1px solid $form-control-border;
  background: $white;

  @include transition(
    border ease 0.3s,
    color ease 0.3s,
    box-shadow ease 0.3s,
    margin 0.3s ease
  );
  @include border-radius(3px);

  &::placeholder {
    color: $text-dark-normal;
    opacity: 1;
    /* Firefox */
  }

  &:-ms-input-placeholder {
    color: $text-dark-normal;
  }

  &::-ms-input-placeholder {
    color: $text-dark-normal;
  }

  &:focus {
    border-color: $form-control-active-border;

    @include noShadow();
  }

  &[disabled] {
    border-color: $form-control-disable-border;
    background: $form-control-disable-background;
    cursor: not-allowed;
  }

  &[readonly] {
    border-color: $form-control-disable-border;
    background: $form-control-disable-background;
    cursor: not-allowed;
  }
}

textarea {
  resize: none;
  height: auto;
}
.app-c-form--postion {
  position: relative;
}

input {
  &.app-c-placeholder-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.form-group {
  margin-bottom: 16px;
  position: relative;

  .help-block {
    display: none;
    color: $white;
    background: $default-red;
    text-align: left;
    font-weight: 500;
    font-size: 11px;
    position: absolute;
    bottom: 100%;
    right: 0;
    margin-bottom: 2px;
    line-height: 18px;
    padding: 1px 8px;

    @include border-radius(3px);
  }
  .app-c-form--label {
    color: $text-color;
    font-size: 11px;
    font-weight: 500;
    display: block;
    line-height: 14px;
    margin-bottom: 2px;
  }
  .app-c-form--value {
    font-size: 13px;
    font-weight: 400;
    color: $text-color;
    display: block;
    line-height: normal;
  }

  &.app-c-form--tex-value {
    .form-control {
      padding-right: 64px;
    }
    .app-c-form__value {
      color: $text-dark-extra;
      font-size: $text-md;
      font-weight: 400;
      position: absolute;
      line-height: normal;
      top: 7px;
      right: 7px;
    }
  }
  &.app-c-form--mandatory {
    .app-c-form--label {
      &:after {
        content: '*';
        color: $form-error;
        display: inline-block;
        font-size: 16px;
        line-height: 10px;
        margin-left: 3px;
        position: relative;
        top: 1px;
      }
    }
  }
}

.has-error {
  &:hover {
    .help-block {
      opacity: 0;
    }
  }

  .form-control {
    &.ng-dirty {
      &.ng-invalid {
        border-color: $form-error;
        background: $form-error-background;

        &:focus {
          border-color: $form-error;
        }

        @include boxShadow(
          0 0.3px 0.9px rgba(221, 0, 0, 0.024),
          0 0.9px 2.4px rgba(221, 0, 0, 0.035),
          0 2.1px 5.7px rgba(221, 0, 0, 0.046),
          0 5px 14px rgba(221, 0, 0, 0.07)
        );

        ~ .help-block {
          display: block;
        }
      }
    }
  }
  .help-block {
    display: none;
    pointer-events: none;

    @include transition(opacity 0.3s ease, visibility 0.3s ease);

    &.error {
      display: block;
    }
  }

  &.is-valid {
    position: relative;

    .help-block {
      position: absolute;
      font-size: 17px;
      min-width: 230px;
      color: $form-error;
      user-select: none;
    }
  }
}

.app-c-group--input {
  position: relative;

  @include flexbox();

  .app-l-group__input-form {
    width: 100%;
    position: relative;
  }
  .app-l-group__icon {
    margin-left: 4px;

    @include flex-shrink(0);
    @include align-self(center);

    .app-c-btn {
      + .app-c-btn {
        margin-left: 4px;
      }
    }
  }
}

.app-c-group--input-left {
  margin-bottom: 16px;

  @include flexbox();

  .app-l-group__control {
    padding-right: 8px;

    @include flex-shrink(0);
    @include align-self(center);

    .app-c-checkbox {
      margin-top: 20px;
    }
  }
  .app-l-group__form {
    position: relative;
    width: 100%;
  }
}

.app-c-search {
  position: relative;

  i {
    font-size: 14px;
    position: absolute;
    top: 8px;
    left: 11px;
    color: $text-dark-extra;
  }
  .form-control {
    padding-left: 32px;
  }
}

// Mat form field

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: $white;
  border: 1px solid rgb(217, 225, 233);
}
.mat-form-field-appearance-fill .mat-form-field-underline::before,
.mat-form-field-ripple {
  background-color: rgb(145, 155, 171);
}
.mat-form-field-label {
  color: rgba(28, 40, 52, 0.8);
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0.27px;
  line-height: normal;
}

.app-l-mat-form-field {
}

.app-l-form-field-holder {
  max-width: 100%;
  position: relative;
  min-width: 0;

  @include flexbox();
  @include flex-direction(column);

  + .app-l-form-field-holder {
    margin-top: 16px;
  }
}

.cdk-overlay-pane {
  .mat-select-panel-wrap {
    margin-top: 40px;
    margin-bottom: 40px;

    .mat-select-panel {
      .mat-option {
        @include transition(background ease 0.2s);

        &:hover,
        &:focus,
        &.mat-selected {
          background: lighten($black, 95%);
        }
      }
    }
  }
}
