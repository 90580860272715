$margin-base: 10px;
$margin-sm:   5px;
$margin-lg:   15px;
$margin-xl:   30px;
$margin-xxl:  50px;

$padding-xl:   30px;
$padding-lg:   10px;
$padding-base: 10px;
$padding-sm:   5px;

@mixin breakpoint($breakpoint) {
    $value: map-get($breakpoints, $breakpoint);
    @if $value != null {
        @media (min-width: $value) {
            @content;
        }
    }
}

$width-xs: $screen-xs;
$width-sm: $screen-sm;
$width-md: $screen-md;
$width-lg: $screen-lg;
$width-xl: 1280px;

$breakpoints: (
    // Small screen / phone
    sm: $width-sm,
    // Medium screen / tablet
    md: $width-md,
    // Large screen / desktop (980 + (12 * 2)) <= container + gutters
    lg: $width-lg,
    // Extra large screen / wide desktop
    xl: $width-xl
) !default;
