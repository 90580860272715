// General button sizes
@import '../core/mixins/border-radius';
@import '../core/mixins/transform';
@import '../core/mixins/transitions';
@import '../core/mixins/button-size';
@import '../core/mixins/flexbox';
@import '../core/mixins/box-shadow';

@import '../variables/variables';
@import '../theme/theme';

.app-c-btn {
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  border: 1px solid transparent;
  font-weight: 600;
  color: #4f3f43;
  padding: 5px 24px;
  font-size: $text-md;
  line-height: 18px;
  outline: none;

  @include transition(
    background ease 0.3s,
    color ease 0.3s,
    border ease 0.3s,
    box-shadow ease 0.3s
  );
  @include inline-flex();
  @include align-items(center);
  @include border-radius(3px);

  + .app-c-btn {
    margin-left: 8px;
  }

  &:hover {
    text-decoration: none;
    outline: none;
  }

  &:active,
  &.active {
    outline: 0;
    background-image: none; // @include boxShadow(inset 0 3px 5px rgba(0,0,0,.125));

    @include noShadow();
  }

  &:focus {
    @include noShadow();
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    cursor: $cursor-disabled;
    opacity: 0.6;
    pointer-events: none;

    @include boxShadow(none);

    &:hover,
    &.hover {
      @include boxShadow(none);
    }
  }

  &.app-c-btn--small {
  }
}

.app-c-btn--primary {
  background: $app-brand-primary;
  border-color: $app-brand-primary;
  color: $white;
  min-width: 80px;
  text-transform: uppercase;

  @include justify-content(center);

  &:hover,
  &:active,
  &:focus {
    background: $app-brand-primary-dark;
    border-color: $app-brand-primary-dark;
    outline: none;
  }

  &[disabled] {
    background: $app-brand-primary-disable;
    border-color: $app-brand-primary-disable;
    color: $text-dark-normal;
  }
}

.app-c-btn--secondary {
  background: $white;
  border-color: $app-brand-secondary;
  color: $app-brand-secondary;
  min-width: 80px;
  text-transform: uppercase;

  @include justify-content(center);

  &:hover,
  &:active,
  &:focus {
    outline: none;
    background: #f2ecf8;
    color: $app-brand-secondary;
    // background: $app-brand-primary-disable;
  }

  &[disabled] {
    color: #9d999a;
    background: $app-brand-primary-disable;
    border-color: lighten(desaturate($app-brand-primary, 61.41), 65.49);
  }
}

.app-c-btn--add-condition {
  background: $white;
  border-color: $app-brand-secondary;
  color: $app-brand-secondary;
  min-width: 80px;
  text-transform: uppercase;

  @include justify-content(center);

  &:hover,
  &:active,
  &:focus {
    outline: none;
    background: #f2ecf8;
    color: $app-brand-secondary;
    // background: $app-brand-primary-disable;
  }
}

.app-c-btn--icon-text {
  border: 1px solid $app-brand-primary;
  background: #ffffff;
  padding-left: 15px;
  padding-right: 15px;
  outline: none;

  i {
    font-size: 14px;
    margin-right: 7px;

    @include flex-shrink(0);
  }
  &:hover,
  &:focus {
    outline: none;
  }
}

.app-c-btn--icon-text-secondary {
  border: 2px solid;
  border-color: $app-brand-secondary;
  background: #ffffff;
  padding-left: 15px;
  padding-right: 15px;
  outline: none;

  i {
    font-size: 14px;
    margin-right: 7px;

    @include flex-shrink(0);
  }
  &:hover,
  &:focus {
    outline: none;
  }

  > span {
    color: $app-brand-secondary;
    font-weight: 600;
  }
}
.app-c-btn--icon-text-teritary {
  @extend .app-c-btn--icon-text-secondary;

  border-color: #919bab;
  background: #919bab !important;

  &:hover,
  &:focus {
    border-color: #b4b8c0;
    background: #b4b8c0 !important;
    color: #fff;
  }
}

.app-c-btn--text {
  background: none;
  color: #555e67;
  font-size: 13px;
  font-weight: 700;
}

.back-purple {
  background: #8c5ac2;
  color: white;
  width: 173px !important;
  height: 48px !important;
  font-size: 14px;
}

.app-c-popdown-btn {
  border: solid #8c5ac2;
  border-width: 2px 2px 2px 1px;
  padding: 4px 9px 4px 9px;
  outline: none;
  background-color: white;
}

.app-c-btn--success {
  background: $dark-green;
  color: $white;
  border-color: $dark-green;

  &:hover {
    background: darken($dark-green, 10%);
    border-color: $dark-green;
    outline: none;
  }

  &:active,
  &:focus {
    @include noShadow();
    outline: none;
  }
}

.app-c-btn--danger {
  background: $dark-red;
  color: $white;
  border-color: $dark-red;

  &:hover {
    background: darken($dark-red, 10%);
    border-color: $dark-red;
    outline: none;
  }

  &:active,
  &:focus {
    outline: none;

    @include noShadow();
  }
}

.app-c-btn--warning {
  background: $dark-yellow;
  color: $white;
  border-color: $dark-yellow;

  &:hover {
    background: darken($dark-yellow, 10%);
    border-color: $dark-yellow;
    outline: none;
  }

  &:active,
  &:focus {
    outline: none;

    @include noShadow();
  }
}

.app-c-btn--info {
  background: $dark-blue;
  color: $white;
  border-color: $dark-blue;

  &:hover {
    background: darken($dark-blue, 10%);
    border-color: $dark-blue;
    outline: none;
  }

  &:active,
  &:focus {
    outline: none;

    @include noShadow();
  }
}

.app-c-btn--light {
  background: $light-grey;
  border-color: $light-grey;

  &:hover {
    background: darken($light-grey, 10%);
    border-color: $light-grey;
    outline: none;
  }

  &:active,
  &:focus {
    outline: none;

    @include noShadow();
  }
}

.app-c-btn--dark {
  background: $dark-grey;
  color: $white;
  border-color: $dark-grey;

  &:hover {
    background: darken($dark-grey, 10%);
    border-color: $dark-grey;
    outline: none;
  }

  &:active,
  &:focus {
    outline: none;

    @include noShadow();
  }
}

.app-c-btn--link {
  background: transparent;
  border-color: transparent;
  color: $app-brand-primary;
  text-decoration: underline;
  font-size: $text-md;
  padding: 0;

  &:hover {
    color: $app-brand-primary;
    text-decoration: underline;

    @include noShadow();
  }
}

.app-c-btn--addon {
  i {
    margin-right: 5px;
  }
}

.app-c-btn--block {
  display: block;
  width: 100%;

  + .app-c-btn--block {
    margin-left: 0px;
    margin-top: 10px;
  }
}

.app-c-btn--transparent {
  border: 1px solid #363a43;
  background-color: #1a1f30;
  color: #636a7e;

  @include border-radius(16px);

  &:hover {
    border: 1px solid #81889c;
    background-color: #3f4761;
    color: rgba(255, 255, 255, 0.9);
    outline: none;
  }

  &:active,
  &:focus {
    outline: none;

    @include noShadow();
  }
}

// Button Sizes
// --------------------------------------------------
.app-c-btn-lg {
  height: auto !important;
  padding: 15px 16px;
  font-size: 16px;
}

.app-c-btn-sm {
  height: auto !important;
  padding: 7px 10px;
  font-size: $text-md;
}

.app-c-btn-xs {
  height: auto !important;
  padding: 4px 8px;
  font-size: 10px;
}

//Button group
//----------------------------------------------------
.app-c-btn-group {
  @include inline-flex();
  position: relative;
  vertical-align: middle;

  > .app-c-btn {
    position: relative;
    @include flex(1, 1, auto);

    + .app-c-btn {
      margin-left: 0px;
    }

    &:not(:first-child),
    &:not(.dropdown-toggle) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:not(:last-child),
    &:not(.dropdown-toggle) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
}

.app-c-btn--icon {
  // background: transparent;
  color: $app-brand-primary;
  padding: 0;
  font-size: 20px;
  outline: none;

  @include border-radius(3px);
  @include inline-flex();
  @include align-items(center);
  @include justify-content(center);

  &:hover {
    outline: none;
    background: #7ab0dc;
    // background: $app-brand-primary-light;
    color: $white;
  }
  &:focus,
  &:active {
    outline: none;
    background: $app-brand-primary-dark;
    //color: $white;
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    cursor: $cursor-disabled;
    opacity: 0.6;
    pointer-events: none;
    color: #c1cbd7;

    @include boxShadow(none);

    &:hover,
    &.hover {
      @include boxShadow(none);
    }
  }

  &.app-c-btn--icon-primary {
    background: $app-brand-primary;
    border-color: $app-brand-primary;
    color: $white;

    &:hover {
      background: lighten($app-brand-primary, 8%);
      border-color: lighten($app-brand-primary, 8%);
      outline: none;
    }

    &:active,
    &:focus {
      background: lighten($app-brand-primary, 12%);
      border-color: lighten($app-brand-primary, 12%);
    }
    &[disabled] {
      background: lighten($app-brand-primary, 16%);
      border-color: lighten($app-brand-primary, 16%);
    }
  }

  &.app-c-btn--icon-border {
    background: $white;
    border: lighten(desaturate($app-brand-primary, 78.65), 36.47) 1px solid;
    color: $text-dark-medium;

    @include border-radius(3px);

    &:hover {
      outline: none;
      border-color: $app-brand-primary;
      background: $app-brand-primary;
      color: $white;
    }
    &:focus,
    &:active {
      border-color: $app-brand-primary-dark;
      color: $white;
      background: $app-brand-primary-dark;
    }
  }
}
.app-c-btn--pagination {
  padding: 1px 8px;
  background: transparent;
  color: rgba(28, 40, 52, 0.6);
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0.25px;
  line-height: normal;

  @include border-radius(3px);
  @include inline-flex();
  @include align-items(center);
  @include justify-content(center);

  &:hover {
    outline: none;
    background: #2c3e50;
    color: $white;
  }
  &:focus,
  &:active,
  &.active {
    outline: none;
    background: #2c3e50;
    color: $white;
  }
  &.pagination-nav {
    font-weight: bold;
    color: rgba(28, 40, 52, 0.8);
    &:hover {
      color: $white;
    }
    &:focus,
    &:active,
    &.active {
      color: $white;
    }
  }
}
.app-c-btn--icon-30px {
  font-size: 16px;
}

.app-c-btn--icon-14px {
  height: 14px;
}

.app-c-btn--icon--small {
  background: none;
  border: none;
  outline: none;
  padding: 3px;
  font-size: $text-md;
  color: $text-color;

  &:hover,
  &:focus {
    color: $app-brand-primary;
  }
}

//Input group
//----------------------------------------------------

.input-group {
  > .input-group-append {
    .app-c-btn {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }
}

.app-l-status-btn {
  position: relative;

  .app-l-btn-active {
    position: absolute;
    top: 2px;
    right: 2px;
    bottom: auto;
    left: auto;

    width: 11px;
    height: 11px;
    border: solid 1px transparent;
    background: rgb(211, 68, 82);

    @include border-radius(50%);
    @include boxShadow(0px 0px 0px 1px rgba(255, 255, 255, 1));

    &.floating-btn {
      top: 4px;
      right: 4px;
    }
  }
}

.app-l-btn-grey {
  background: rgb(145, 155, 171);
  color: $white;
}

.app-l-btn-grey--outline {
  border-color: rgb(145, 155, 171);
  background-color: $white;
  text-transform: uppercase;

  &:hover,
  &:focus {
    background-color: rgb(145, 155, 171);
    color: $white;
  }
}

.app-l-btn-transparent {
  background: transparent;

  &:hover {
    color: $black;
    background: lighten($black, 95%);
  }
}

.app-l-btn-large {
  padding: 14px 24px;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}

.app-l-btn-red {
  background: rgb(210, 65, 79);
  color: $white;

  &:hover,
  &:focus {
    background: rgb(210, 65, 79, 0.8);
  }
}

.app-l-btn-purple {
  background: rgb(140, 90, 194);
  color: $white;

  &:hover,
  &:focus {
    background: rgb(140, 90, 194, 0.8);
  }
}
.app-c-green-button {
  width: 15px;
  height: 15px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 20px;
}
.app-c-label-icon-button {
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0.15px;
  line-height: 20px;
  color: rgb(13, 107, 184);
  margin-left: 10px;
}

.m-arrow-icon {
  font-size: 12px;
  margin-left: 10px;
}
.app-c-transparent {
  background: transparent !important;
  &:hover {
    color: #0d6bb8 !important;
  }
}

.app-c-btn--icon-text-green {
  @extend .app-c-btn--icon-text-secondary;
  color: $app-brand-dark-green;
}

.header-icon {
  width: 19px;
  height: 19px;
  margin-left: 10px;
  color: $app-brand-primary;
  font-weight: bold;
  line-height: 3;
}

.app-c-btn-cancel {
  background-color: transparent;
  border: 1px solid transparent;
  color: rgba(28, 40, 52, 0.8);
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1.25px;
  line-height: 16px;
  text-transform: uppercase;
}
.app-c-btn-export {
  background: $app-brand-primary;
  border-radius: 4px;
  border: 1px solid $app-brand-primary;
  height: 32px;
  color: rgb(255, 255, 255);
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 16px;
  text-align: center;
  width: 125px;
}

.icon-add-panel {
  background: $app-brand-primary;
  border-radius: 4px;
  border: 1px solid $app-brand-primary;
  height: 32px;
  color: rgb(255, 255, 255);
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 16px;
  text-align: center;
  width: 32px !important;
}

.icon-add-panel-cancel {
  background: $white;
  border-radius: 4px;
  border: 1px solid $white;
  height: 32px;
  color: rgb(255, 255, 255);
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 16px;
  text-align: center;
  width: 32px !important;
  i {
    color: $app-brand-primary;
  }
}

.nounderscore {
  text-decoration: none !important;
  cursor: pointer;
}

.buttonSearch {
  background: $app-brand-primary;
  border-radius: 0px 4px 4px 0px;
  border: 1px solid $app-brand-primary;
  color: rgb(255, 255, 255);
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 16px;
  text-align: center;
  width: 40px;
  height: 47px;

}
