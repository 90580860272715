/// @param {Length} $size - Horizontal scrollbar's height and vertical scrollbar's width
/// @param {Color} $foreground-color - Scrollbar's color
/// @param {Color} $background-color [mix($foreground-color, white, 50%)] - Scrollbar's color
/// @example scss - Scrollbar styling
///   @include scrollbars(.5em, slategray);
@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white,  30%)) {
    // For Google Chrome
    ::-webkit-scrollbar {
            width:  $size;
            height: $size;
    }

    ::-webkit-scrollbar-thumb {
            background: $foreground-color;
            border-radius: 10px;
    }

    ::-webkit-scrollbar-track {
            background: $background-color;
            border-radius: 0px;
    }

    // For Internet Explorer
    body {
        scrollbar-face-color: $foreground-color;
        scrollbar-track-color: $background-color;
    }
}
