.app-l-agrregate-panel {
  background: $white;
  border-radius: 4px;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.14),
    0px 3px 3px -2px rgba(0, 0, 0, 0.12);
  padding: 9px 9px;
  display: flex;
}

.app-l-seperator {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .percentage-label {
    width: 33px;
  }
  .percentage {
    font-weight: 600;
  }
}

.app-l-aggregate-header {
  font-size: 14px;
  height: 18px;
  text-align: center;
  width: 100%;
  padding-bottom: 27px;
}

.app-l-aggregate-value {
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  word-break: break-all;
  line-height: 23px;
}

.progress-bar-vertical {
  width: 20px;
  min-height: 45px;
  display: flex;
  align-items: flex-end;
  float: left;
}

.progress-bar-vertical .progress-bar {
  width: 100%;
  height: 0;
  -webkit-transition: height 0.6s ease;
  -o-transition: height 0.6s ease;
  transition: height 0.6s ease;
}

.app-l-text-section {
  width: 100%;
}

.image-width {
  display: flex;
  align-items: center;
}
