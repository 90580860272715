@import '../core/mixins/box-shadow';
@import '../core/mixins/animation';

.app-l-content-holder {
  width: 100%;
  min-width: 0;
  height: 100%;
  min-height: 0;

  @include flexbox();
  @include flex-direction(column);
}

.app-l-content-wrap,
.app-l-content-grid {
  width: 100%;
  min-width: 0;
  height: 100%;
  min-height: 0;

  @include flexbox();
  @include flex-direction(column);
}

.app-l-vertical-content {
  width: 100%;
  min-width: 0;
  min-height: 0;

  @include flexbox();
  @include flex-direction(column);
}

.app-l-panel-closer {
  z-index: 998;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;

  background-color: rgba(255, 255, 255, 0.05);
}

.app-l-slide-panel {
  z-index: 999;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: auto;

  width: 403px;
  min-width: 0;
  height: 100%;
  min-height: 0;

  @include flexbox();
  @include flex-direction(column);
  @include transform(translateX(110%));
  @include transition(transform 0.2s ease);

  &.open-panel {
    @include transform(translateX(0));
  }

  .app-l-slide-panel-content {
    width: 100%;
    height: 100%;
    min-height: 0;
    position: relative;
    background: rgb(250, 251, 252);
    z-index: 0;

    @include flexbox();
    @include flex-direction(column);
    @include boxShadow(0px 1px 4px 3px rgba(0, 0, 0, 0.2));

    .app-l-slide-panel-close {
      position: absolute;
      z-index: 2;
      top: 4px;
      right: 0;
      bottom: auto;
      left: auto;

      height: 40px;
      min-width: 40px;
      background-color: transparent;
      border: solid 1px transparent;
      padding: 8px 16px;
      cursor: pointer;

      @include flexbox();
      @include align-items(center);

      &:hover,
      &:focus {
        i {
          opacity: 1;
        }
      }

      > i {
        font-size: 16px;
        line-height: normal;
        cursor: pointer;
        opacity: 0.4;
        color: $text-color;

        @include transition(opacity 0.2s ease);
      }
    }

    .app-l-panel-tabs {
      width: 100%;
      height: 100%;
      min-height: 0;
      z-index: 1;

      @include flexbox();
      @include flex-direction(column);

      .mat-tab-group {
        width: 100%;
        height: 100%;
        min-height: 0;
        z-index: 1;

        @include flexbox();
        @include flex-direction(column);
      }

      .app-l-panel-tab-symbol {
        font-size: 23px;
        color: $black;
        cursor: pointer;
        line-height: normal;
        background-color: transparent;
        border: solid 1px transparent;
        position: relative;
        padding: 12px 32px;

        &:hover,
        &:focus {
          i {
            color: $app-brand-primary;
          }
        }

        &:before {
          position: absolute;
          top: auto;
          right: 0;
          bottom: 0;
          left: 0;
          margin: auto;
          width: 0;
          height: 2px;
          background-color: $app-brand-primary;

          @include transition(width 0.2s ease-in-out, width 0.2s ease-in-out);
        }

        > i {
          font-size: 16px;
          color: $black;
          cursor: pointer;
          line-height: normal;
        }
      }

      .app-l-panel-tab-content {
        width: 100%;
        min-width: 0;
        height: 100%;
        min-height: 0;
        padding: 16px 0px;
        overflow-y: auto;

        @include flexbox();
        @include flex-direction(column);

        .app-l-panel-tab-header {
          width: 100%;
          min-height: 0;
          padding: 0px 24px;

          @include flexbox();
          @include align-items(center);

          .app-l-panel-heading {
            color: $text-color;
            font-size: 20px;
            font-weight: 600;
            letter-spacing: 0.25px;
            margin: 0;
          }

          + .app-l-card-group {
            margin-top: 12px;
          }
        }

        .app-l-card-group {
          min-height: 0;
          max-height: 100%;
          height: 100%;
          overflow-y: auto;

          @include flexbox();
          @include flex-direction(column);

          .app-l-card-group-header {
            padding: 0px 24px;

            + .app-l-card-set-wrap {
              margin-top: 8px;
            }
          }

          .app-l-card-set-wrap {
            padding: 0px 24px;

            @extend .app-l-vertical-content;
            @include flex-shrink(0);

            .app-l-data-card {
              background: $white;
              border: 1px solid rgb(228, 233, 239);
              min-width: 0;
              width: 100%;
              padding: 16px;

              @include flexbox();
              @include flex-direction(column);
              @include boxShadow(0px 2px 0px 0px rgb(217, 225, 233));
              @include border-radius(4px);

              + .app-l-data-card {
                margin-top: 8px;
              }

              .app-l-card-entry {
                width: 100%;
              }
            }

            + .app-l-data-sections {
              margin-top: 24px;
            }
          }

          .app-l-data-sections {
            padding: 0px 24px;

            @extend .app-l-vertical-content;
            @include flex-shrink(0);

            .app-l-card-group-header {
              padding: 0;
            }

            + .app-l-data-sections {
              margin-top: 16px;
            }

            .app-l-card-group-header {
              + .app-l-data-section {
                margin-top: 8px;
              }
            }

            .app-l-data-section {
              min-width: 0;
              width: 100%;

              @include flexbox();
              @include flex-direction(column);

              .app-l-data-entry {
                @include boxShadow(0px 1px 0px 0px rgb(228, 233, 239));

                .app-l-data-content {
                  padding: 4px 0px;
                }

                + .app-l-data-entry {
                }
              }
            }
          }
        }

        .app-l-redirect {
          width: 100%;
          min-width: 0;
          padding: 0px 24px;

          @include flex-shrink(0);
        }
      }
    }
  }
}

.app-l-full-card-wrap {
  @extend .app-l-content-wrap;

  .app-l-fullcard {
    background: rgb(255, 255, 255);
    width: 100%;
    min-height: 0;
    padding: 32px;

    @include border-radius(4px);
    @include flexbox();
    @include flex-direction(column);
    @include flex-shrink(0);
    @include boxShadow(
      0px 4px 5px 0px rgba(0, 0, 0, 0.14),
      0px 1px 10px 0px rgba(0, 0, 0, 0.12),
      0px 2px 4px -1px rgba(0, 0, 0, 0.2)
    );

    + .app-l-fullcard {
      margin-top: 32px;
    }

    .app-l-fullcard-header {
      width: 100%;
      min-height: 0;

      @include flexbox();
      @include align-items(center);
      + .app-l-fullcard-body {
        margin-top: 16px;
      }
    }

    .app-l-fullcard-body {
      width: 100%;
      min-height: 0;

      @include flexbox();
      @include flex-direction(column);
    }
    .app-l-stored-tag {
      @include flexbox();
      @include justify-content(space-between);
      .icon-delete {
        color: #0d6bb8;
        cursor: pointer;
      }
      .app-l-consented {
        @include flexbox();
        @include justify-content(center);
        @include align-items(center);
        width: 109px;
        height: 30px;
        border-radius: 4px;
        background: #e4efe9;
        color: rgb(28, 40, 52);
        font-size: 14px;
        letter-spacing: 0.23px;
        line-height: 20px;
      }
    }
  }
}

.app-l-field-group {
  max-width: 100%;
  min-width: 0;

  @include flexbox();

  .app-l-field-set {
    margin: -8px;
    min-width: 0;

    @include flexbox();
    @include flex-wrap(wrap);
    @include align-items(flex-start);

    .app-l-field-holder {
      margin: 8px;
      min-width: 0;

      @include flexbox();

      &.app-l-btn-holder {
        padding-top: 11px;
      }
    }
  }
}

.app-l-tag-group {
  width: 100%;
  min-height: 0;

  @include flexbox();
  @include flex-direction(column);

  .app-l-add-tag {
    width: 100%;
    min-height: 0;
    padding: 8px 0px;

    @include flexbox();
    @include align-items(flex-start);

    .app-l-tag-field {
      + .app-l-tag-field-btn {
        margin-left: 24px;
        margin-top: 11px;
      }
    }

    .app-l-tag-field-btn {
    }

    + .app-l-tag-set {
      margin-top: 0px;
    }
  }

  .app-l-tag-color {
    width: 26px;
    height: 56px;
    background: rgb(145, 155, 171);
    margin: 0;

    @include border-radius(12px);

    + .app-l-tag-field {
      margin-left: 17px;
    }
    + .app-l-stored-tag-label {
      margin-left: 17px !important;
    }

    &.approved {
      background: rgb(5, 166, 120);
    }
    &.consented {
      background: rgb(242, 114, 151);
    }
    &.restructure {
      background: rgb(210, 65, 79);
    }
    &.pending {
      background: rgb(255, 171, 0);
    }
    &.docs-out {
      background: rgb(15, 121, 208);
    }
    &.submitted {
      background: rgb(140, 90, 194);
    }
  }

  .app-l-tag-set {
    width: 100%;
    min-height: 0;

    @include flexbox();
    @include flex-direction(column);

    .app-l-stored-tag {
      width: 100%;
      min-height: 0;
      padding: 4px 0px;

      @include flexbox();
      @include align-items(center);

      + .app-l-stored-tag {
        border-top: solid 1px rgb(228, 233, 239);
      }

      &:last-child {
        border-bottom: solid 1px rgb(228, 233, 239);
      }
    }
  }
}

.app-l-card-group-header {
  text-transform: uppercase;
  color: $text-color;
  opacity: 0.8;
  font-size: 12px !important;
  font-weight: 700 !important;
  letter-spacing: 1.5px !important;
  line-height: 16px !important;
  margin: 0 !important;
}

.app-l-card-entry {
  width: 100%;

  @include flex-shrink(0);
  @include flexbox();
  @include align-items(flex-start);

  &.app-l-status-entry {
    .app-l-card-content {
      margin-right: 8px;
    }
  }

  .app-l-card-content {
    max-width: 100%;
    padding: 2px 0px;

    @include flexbox();
    @include align-items(flex-start);

    .app-l-card-content-symbol {
      margin: 0;
      padding: 3px 0 0 0;

      @include flexbox();
      @include flex-shrink(0);

      > i {
        font-size: 14px;
        color: $text-color;
        line-height: normal;
        cursor: default;
      }

      + .app-l-card-text {
        margin-left: 16px;
      }

      &.card-action {
        background-color: transparent;
        border: solid 1px transparent;
        padding: 0;
        outline: 0;
        cursor: pointer;

        i {
          color: $app-brand-primary;
        }
      }
    }

    .app-l-card-text {
      color: $text-color;
      font-weight: 400;
      height: 24px;
      letter-spacing: 0.22px;
      margin: 0;

      + .app-l-card-content-symbol {
        margin-left: 16px;
      }
    }
  }

  .app-l-data-card-badge {
    margin-left: auto;
  }
}

.app-l-data-entry {
  width: 100%;

  @include flex-shrink(0);
  @include flexbox();
  @include align-items(flex-start);

  .app-l-data-content {
    max-width: 100%;
    width: 100%;
    padding: 2px 0px;

    @include flexbox();
    @include align-items(flex-start);

    @media screen and (max-width: 767px) {
      @include flex-direction(column);
      @include justify-content(flex-start);
    }

    .app-l-data-label {
      color: $text-color;
      opacity: 0.8;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      margin: 0;
      padding: 0 8px 0 0;
      width: calc(40%);

      @include flex-shrink(0);
    }

    .app-l-data-value {
      color: $text-color;
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
      margin: 0;
    }
  }
}

.app-l-data-card-badge {
  background: rgb(140, 90, 194);
  min-width: 0;
  padding: 4px 4px 2px 4px;

  @include border-radius(4px);
  @include flexbox();

  .app-l-card-badge-text {
    text-transform: uppercase;
    cursor: default;
    color: $white;
    font-size: 10px;
    line-height: 13px;
    font-weight: 600;
    letter-spacing: 0.13px;
    text-align: center;
    margin: 0;
  }
}

.app-l-option-dropdown {
  position: relative;
  z-index: 2;
}

.app-l-form-dropdown {
  width: 362px;
  max-width: 362px;
  padding: 0;

  @include flexbox();
  @include flex-direction(column);
  @include border-radius(4px);
  @include boxShadow(
    0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12),
    0px 2px 4px -1px rgba(0, 0, 0, 0.2)
  );

  .app-l-form-dropdown-contents {
    padding: 20px 0px;
    width: 100%;
    position: relative;

    @include flexbox();
    @include flex-direction(column);
  }

  &.right-aligned {
    right: 100px !important;
  }

  .app-l-dropdown-close {
    position: absolute;
    top: 8px;
    right: 8px;
    bottom: auto;
    left: auto;

    background-color: transparent;
    border: solid 1px transparent;
    padding: 2px;
    outline: none;

    &:focus,
    &:hover {
      i {
        color: $black;
        opacity: 1;
      }
    }

    > i {
      color: $text-color;
      opacity: 0.5;
      font-size: 16px;
      line-height: normal;
      cursor: pointer;

      @include transition(color 0.2s ease, opacity 0.2s ease);
    }
  }

  .app-l-form-header {
    padding: 0px 20px;
    width: 100%;
    position: relative;

    @include flexbox();
    @include align-items(center);

    .app-l-form-header-text {
      color: $text-color;
      font-size: 20px;
      font-weight: 400;
      cursor: default;
      margin: 0;
    }

    + .app-l-form-body {
      margin-top: 16px;
    }
  }

  .app-l-form-body {
    padding: 0px 20px;
    width: 100%;
    height: 100%;
    min-height: 0;
    //max-height: 200px;
    overflow-y: auto;

    @include flexbox();
    @include flex-direction(column);
  }

  .app-l-form-footer {
    padding: 0px 20px;
    width: 100%;

    @include flexbox();
    @include align-items(center);
    @include justify-content(flex-end);

    .app-l-create {
      min-width: 98px;
      height: 49px;
      i {
        display: block;
        margin: 0 auto;
        @include animation(rotation 2s infinite linear);
      }
    }
  }
}

.mat-autocomplete-panel {
  background-color: #f1f1f1 !important;
}

.app-l-selector-field {
  .mat-icon-button {
    position: absolute;
    top: -5px !important;
    right: -10px !important;
    bottom: auto;
    left: auto;
    width: 40px !important;
    height: 20px !important;
    line-height: 0 !important;

    .mat-icon {
      color: #cccccc !important;
    }
  }
}

@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}

@-moz-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}

@-o-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}

@-ms-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}

.app-l-settings {
  min-width: 70px;
  i {
    display: block;
    margin: 0 auto;
    @include animation(rotation 2s infinite linear);
  }
}
