.progress-bar-vertical {
    width: 18px;
    min-height: 10px;
    display: flex;
    align-items: flex-end;
    float: left;
    height: 40px;
}

.progress-bar-vertical .progress-bar {
    width: 100%;
    height: 0;
    -webkit-transition: height 0.6s ease;
    -o-transition: height 0.6s ease;
    transition: height 0.6s ease;
}

.progress-span {
    text-align: right;
    width: 27px;
    margin-right: 5px;
}
.progress-green {
    background-color: #05a678;
}
.progress-red {
    background-color: #d34452;
}
.progress-yellow {
    background-color: #ffab00;
}
