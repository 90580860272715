  .carousel-indicators li {
    background-color: #0d6bb8 !important;
  }
  .carousel-indicators li {
    width: 12px !important;
  height: 12px !important;
  border-radius: 50% !important;
  
  }
  .carousel {
    position: relative;
    height: 300px !important;
  }
  .carousel-control-prev-icon{  
    background-image: url('/assets/images/arrow-left.svg') !important;
    //margin-top: 216px;
    margin-left: 35px;
  }
  .carousel-control-next-icon{
      background-image: url('/assets/images/arrow-right.svg') !important;
      margin-right: 35px;
  }
  .carousel-control-next {
    right: 0;
    margin-right: 70px !important;
    top: 242px;
    bottom: 24px;
  }
  .carousel-control-prev {
    left:0;
    margin-left: 70px !important; 
    top: 242px;
    bottom: 24px;  
  }
  .carousel-indicators {
    bottom: 25px !important;
    z-index: 1;//
  }
  