$ssm: 2px;
$sm: 5px;
$base: 10px;
$lg: 15px;
$lgs: 20px;
$xl: 30px;

//margin
$margin-sm: $sm;
$margin-base: $base;
$margin-lg: $lg;
$margin-xl: $xl;

//padding
$padding-sm: $sm;
$padding-base: $base;
$padding-lg: $lg;
$padding-xl: $xl;

//font-size
$text-sm: $base;
$text-sm-plus: 11px;
$text-md: 12px;
$text-md-plus: 13px;
$text-lg: 14px;
$text-lg-plus: $lg;
$text-xl: 16px;
$text-xl-plus: 17px;

//Font
$font-primary: 'Source Sans Pro' !default;
$font-secondary: -apple-system, BlinkMacSystemFont, 'Poppins', 'Segoe UI',
  'Source Sans Pro', 'Noto Sans', 'Ubuntu', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
$body-font: $font-primary !default;

// Font weights
$font-weight-bold: 700 !default;
$font-weight-semibold: 500 !default;
$font-weight-normal: 400 !default;
$font-weight-light: 300 !default;

// Line heights
$lh-condensed-ultra: 1 !default;
$lh-condensed: 1.25 !default;
$lh-default: 1.5 !default;

$cursor-disabled: not-allowed !default;

$checkbox-size: 18px;
$radio-size: 18px;
$fontawesome-icons: 'Font Awesome 5 Free';
$font-icons: 'design-group';

//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs: 480px !default;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min: $screen-xs !default;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone: $screen-xs-min !default;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm: 768px !default;
$screen-sm-min: $screen-sm !default;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet: $screen-sm-min !default;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md: 992px !default;
$screen-md-min: $screen-md !default;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop: $screen-md-min !default;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg: 1200px !default;
$screen-lg-min: $screen-lg !default;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop: $screen-lg-min !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-sm-min - 1) !default;
$screen-sm-max: ($screen-md-min - 1) !default;
$screen-md-max: ($screen-lg-min - 1) !default;
