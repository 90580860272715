
$app-brand-primary:                      #0f79d0;
$app-brand-primary-dark:                  saturate(darken($app-brand-primary, 3.73%), 0.31);
$app-brand-primary-light:                 lighten(desaturate($app-brand-primary, 51.48), 22.75%);
$app-brand-primary-disable:               lighten(desaturate($app-brand-primary, 54.33), 68.24%);
$app-brand-secondary:                     rgb(140, 90, 194);
// $app-brand-secondary:                     lighten(desaturate($app-brand-primary, 54.33), 71.96%);
$app-brand-dark-green:                    rgb(1,53,38);
$primary-color: #042540;
$app-brand-secondary:#3E7343;

// Images
$blue-decor: url('/assets/images/decor.png');
$green-decor: url('/assets/images/decor_green.png');

// Body styles

$app-body-bg:                           #f2f4f7;

// Text color
$text-color:                             rgb(28, 40, 52);
$text-dark-extra :                       $text-color;
$text-dark-medium :                      lighten(desaturate($app-brand-primary, 77.81), 17.25);
$text-dark-normal :                      lighten(desaturate($app-brand-primary, 78.65), 36.47);
$text-light-normal :                     lighten(desaturate($app-brand-primary, 61.41), 65.49);
$text-light-medium:                      lighten(desaturate($app-brand-primary, 72.31), 70.98);

$white:                                  #fff;
$black:                                  #000;
$generic-border-color:                   lighten(desaturate($app-brand-primary, 72.31), 70.98);

$default-green   : #44AB66;
$default-red     : #EB5757;
$default-red-light : #FFF6F6;

//checkbox-theme
$app-checkbox-border:     lighten(desaturate($app-brand-primary, 78.65), 36.47);
$app-checkbox-bg:         lighten(desaturate($app-brand-primary, 72.31), 70.98);
$app-checkbox-border-disable : lighten(desaturate($app-brand-primary, 72.31), 70.98);

// Radio Button
$app-radio-border:     lighten(desaturate($app-brand-primary, 78.65), 36.47);
$app-radio-bg:         lighten(desaturate($app-brand-primary, 72.31), 70.98);
$app-radio-border-disable : lighten(desaturate($app-brand-primary, 72.31), 70.98);

//form-control

$form-control-border : lighten(desaturate($app-brand-primary, 78.65), 36.47);
$form-control-active-border : $app-brand-primary;
$form-control-disable-border : lighten(desaturate($app-brand-primary, 78.65), 36.47);
$form-control-disable-background : lighten(desaturate($app-brand-primary, 72.31), 70.98);
$form-error: $default-red;
$form-error-background: $default-red-light;

//Tooltip

$tooltip-background : darken(desaturate($app-brand-primary, 61.10), 7.25%);
$tooltip-arrow-background : darken(desaturate($app-brand-primary, 61.10), 7.25%);

// Accordrion
$accordrion-border : lighten(desaturate($app-brand-primary, 61.41), 65.49);
$accordrion-border-active : lighten(desaturate($app-brand-primary, 78.65), 36.47);

//Modal

$modal-close-bg: lighten(desaturate($app-brand-primary, 72.31), 70.98);
$modal-close-bg-hover: lighten(desaturate($app-brand-primary, 61.41), 65.49);

//Toggle Button

$app-toggle-bg: $white;
$app-toggle-active-bg : #05a678;
$app-toggle-inactive-border : #d9e1e9;

// Card
$app-card-border:                       #e6e5e5;
$app-card-header:                       #f9fbff;
$app-card-grey :                        #FCFCFC;
$app-card-shadow                    : #E3E3E3;

//Alert
$app-alert-success:                     #f0fff4;
$app-alert-danger:                      #f8eced;
$app-alert-warning:                     #fffbf0;
$app-alert-info:                        #eff8ff;
$app-alert-light:                       #f6f6f6;
$app-alert-dark:                        #eaeaea;

//General Colors
$light-blue :                            #4c71f0;
$dark-green:                             #5cb837;
$dark-red:                               #ff5630;
$dark-yellow:                            #ffc84c;
$dark-blue:                              #1facfe;
$light-grey:                             #eee;
$dark-grey:                              #a4a4a4;


